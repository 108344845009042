<template>
  <div style="margin-top:200px">
    <button @click="check()">Check store</button>
  </div>
</template>
 
<script>
export default {
  data () {
    return {
      
    }
  },//end of data

  mounted() {
    
  },//end of mounted
  methods: {
    check(){
    }
  }
}
</script> 
 
<style scoped>

</style>